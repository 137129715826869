
// axios.js
import axios from "axios";
import store from "./store/index"; // Import your Vuex store

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://be.silconi.com/api';
// axios.defaults.baseURL = 'http://localhost:8000/api';
// Add an Axios interceptor to include the auth token in requests
axios.interceptors.request.use((config) => {
  const token = store.state.authToken; // Get the token from Vuex store
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Set the Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axios;
