import { createRouter, createWebHistory } from 'vue-router';
import Main from '../views/Main.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    component: () => import('../views/Login.vue'),
    name: 'login'
  },
  {
    path: '/body',
    component: Main,
    children: [
      { path: '/dashboard', component: () => import('../views/Dashboard.vue'), name: 'dashboard', props: true, meta: { roles: ['admin', 'seller'] } },
      { path: '/devp', component: () => import('../views/Maintainance.vue'), name: 'devp', meta: { roles: ['admin'] } },
      { path: '/stock', component: () => import('../views/StockBalance.vue'), meta: { roles: ['admin', 'seller'] } },
      { path: '/stockInReport', component: () => import('../views/StockInReport.vue'), meta: { roles: ['admin'] } },
      { path: '/products', component: () => import('../views/Products.vue'), meta: { roles: ['admin'] } },
      { path: '/productsForm/:id', component: () => import('../components/ProductForm.vue'), name: 'productForm', meta: { roles: ['admin'] } },
      { path: '/sales', component: () => import('../views/Sales.vue'), meta: { roles: ['admin', 'seller'] } },
      {
        path: '/sales-store-summary/:storeId',
        component: () => import('../views/SalesStoreReport.vue'),
        meta: { roles: ['admin'] },
        props: (route) => ({ storeId: route.params.storeId, date: route.query.date })
      },
      { path: '/salesReport', component: () => import('../views/SalesReport.vue'), meta: { roles: ['admin'] } },
      { path: '/salesDay/:date', component: () => import('../views/SalesDay.vue'), meta: { roles: ['admin'] } },
      { path: '/saleForm', component: () => import('../components/SaleForm.vue'), meta: { roles: ['admin', 'seller'] } },
      { path: '/stores', component: () => import('../views/Stores.vue'), meta: { roles: ['admin'] } },
      { path: '/storeForm/:id', component: () => import('../components/StoreForm.vue'), name: 'storeForm', meta: { roles: ['admin'] } },
      { path: '/stockTransferOrderForm/', component: () => import('../components/StockTransferOrderForm.vue'), name: 'storeTransferOrderForm', meta: { roles: ['admin'] } },
      { path: '/storeProducts/:id', component: () => import('../views/StoreProducts.vue'), name: 'storeProducts', meta: { roles: ['admin'] } },
      { path: '/addProductToStore/:id', component: () => import('../components/AddStoreProduct.vue'), name: 'addProductToStore', meta: { roles: ['admin'] } },
      { path: '/stock-transfer', component: () => import('../views/StockTransferOrders.vue'),name: 'stockTransferOrders', meta: { roles: ['admin'] } },
      { path: '/stock-transfer/:id', component: () => import('../views/StockTransfer.vue'), name: 'stockTransfer', meta: { roles: ['admin'] } },
      { path: '/categories', component: () => import('../views/Categories.vue'), meta: { roles: ['admin'] } },
      { path: '/categoryForm/:id', component: () => import('../components/CategoryForm.vue'), name: 'categoryForm', meta: { roles: ['admin'] } },
      { path: '/expenses', component: () => import('../views/Expenses.vue'), meta: { roles: ['admin', 'seller'] } },
      { path: '/expenseForm/:id', component: () => import('../components/ExpensesForm.vue'), name: 'expenseForm', meta: { roles: ['admin', 'seller'] } },
      {
        path: '/unauthorized',
        component: () => import('../views/Unauthorized.vue'),
        name: 'unauthorized'
      },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    component: () => import('../views/Register.vue'),
    name: 'register'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const userRoles = store.getters.getUserRoles; // Use the correct getter here

  // If the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // If the user is not authenticated, redirect to login
    if (!isAuthenticated) {
      next({ name: 'login' });
    } else {
      // Check if the route has role restrictions
      const routeRoles = to.meta.roles;

      // If the route has role restrictions, check if the user's roles match
      if (routeRoles && !routeRoles.some(role => userRoles.includes(role))) {
        // If the user's roles do not match, redirect to an unauthorized page
        next({ name: 'unauthorized' });
      } else {
        // Otherwise, allow the user to proceed
        next();
      }
    }
  } else {
    next(); // Proceed if the route doesn't require authentication
  }
});

export default router;