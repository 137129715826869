<template>
  <loading-spinner ref="loadingSpinner" />
  <router-view />
</template>
<script>
import LoadingSpinner from './components/LoadingSpinner.vue';

export default {
  components: {
    LoadingSpinner,
  },
  watch: {
    $route(to, from) {
      this.$refs.loadingSpinner.show();
      setTimeout(() => {
        this.$refs.loadingSpinner.hide();
      }, 10); // Adjust timeout as needed
    },
  },
};
</script>
<style>

</style>
