import { createStore } from 'vuex';

export default createStore({
  state: {
    authToken: sessionStorage.getItem('authToken') || null,
    userId: sessionStorage.getItem('userId') || null,
    userRoles: JSON.parse(sessionStorage.getItem('userRoles')) || [],
    userEmail: sessionStorage.getItem('userEmail') || null,
    userName: sessionStorage.getItem('userName') || null // Add userName to the state
  },
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
      sessionStorage.setItem('authToken', token);
    },
    setUserRoles(state, roles) {
      state.userRoles = roles;
      sessionStorage.setItem('userRoles', JSON.stringify(roles));
    },
    setUserEmail(state, email) {
      state.userEmail = email;
      sessionStorage.setItem('userEmail', email);
    },
    setUserId(state, id) {
      state.userId = id;
      sessionStorage.setItem('userId', id);
    },
    setUserName(state, name) {
      state.userName = name;
      sessionStorage.setItem('userName', name);
    },
    clearSession(state) {
      state.authToken = null;
      state.userRoles = [];
      state.userEmail = null;
      state.userId = null;
      state.userName = null;
      sessionStorage.clear();
    }
  },
  actions: {
    login({ commit }, { token, user }) {
      commit('setAuthToken', token);
      commit('setUserRoles', user.roles);
      commit('setUserEmail', user.email);
      commit('setUserId', user.id);
      commit('setUserName', user.name); // Store the user name
    },
    logout({ commit }) {
      commit('clearSession');
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    getUserRoles: (state) => state.userRoles,
    getUserEmail: (state) => state.userEmail,
    getUserName: (state) => state.userName // Getter for user name
  }
});
